import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import Layout from '../components/Layout';

import Menu from '../pages/Menus/Menu';

import MenuItem from '../pages/MenuItems/MenuItem';
import AddMenuItem from '../pages/MenuItems/AddMenuItem';

import Attributes from '../pages/Attributes';

import Categories from '../pages/Categories';

import Tables from '../pages/Tables';
import Table from '../pages/Tables/Table';
import AddTable from '../pages/Tables/AddTable';

import Venues from '../pages/Venues';

import Devices from '../pages/Devices';
import EditDevice from '../pages/Devices/EditDevice';
import AddDevice from '../pages/Devices/AddDevice';

import Qrcodes from '../pages/Qrcodes';
import AddQrcode from '../pages/Qrcodes/AddQrcode';

import Orders from '../pages/Orders';
import OrdersLive from '../pages/OrdersLive';
import OrdersTakeaway from '../pages/OrdersTakeaway';
import Order from '../pages/Orders/Order';

import { fetchVenue, fetchVenues } from '../store/venues';
import {
  getVenuesState,
  getVenueState,
  isStandard,
  isDeliveryOnly,
  isPro,
} from '../store/venues/selectors';
import { getFirstLogin } from '../store/signup/firstLogin';
import ReportStaff from '../pages/ReportStaff';
import ModifierAttributes from '../pages/Attributes/ModifierAttributes';
import AddMultipleTables from '../pages/Tables/AddMultipleTables';
import Settings from '../pages/Settings';
import Admin from '../pages/Admin';
import VenueFees from '../pages/VenueFees';
import Images from '../pages/Images';
import PromoCodes from '../pages/PromoCodes';
import Tabs from '../pages/Tabs';
import Tab from '../pages/Tabs/Tab';
import Payouts from '../pages/Payouts';
import CreateCampaign from '../pages/CreateCampaign';
import VenueWatcher from '../components/VenueWatcher';
import EditMobile from '../pages/Mobiles/EditMobile';
import Mobiles from '../pages/Mobiles';
import EditStaff from '../pages/Staff/EditStaff';
import Staff from '../pages/Staff';
import AddStaff from '../pages/Staff/AddStaff';
import LogoUpCo from '../components/LogoUpCo';
import Voucher from '../pages/Vouchers/Voucher';
import CustomerReviews from '../pages/CustomerReviews';
import Home from '../pages/Home';
import Locked, { LOCKTYPES } from '../pages/Locked';
import Personalisation from '../pages/Personalisation';
import SalesAreas from '../pages/SalesAreas';
import SalesArea from '../pages/SalesAreas/SalesArea';
import Discounts from '../pages/Discounts';
import Discount from '../pages/Discounts/Discount';
import DiscountAddItem from '../pages/Discounts/DiscountAddItem';
import AddPrinter from '../pages/Devices/AddPrinter';
import EditPrinter from '../pages/Devices/EditPrinter';
import Tills from '../pages/Tills';
import Team from '../pages/Settings/Team';
import useRoles from '../hooks/useRoles';
import ROLES from '../shared/constants/roles';
import ReportSales from '../pages/ReportSales';
import Promotions from '../pages/Promotions';
import Promotion from '../pages/Promotions/Promotion';
import ReportCustomers from '../pages/ReportCustomers';
import ReportOverview from '../pages/ReportOverview';
import ItemTabs from '../pages/ItemTabs';
import VoucherTabs from '../pages/VoucherTabs';
import OpeningHours from '../pages/Settings/Venue/OpeningHours';
import VenueDetails from '../pages/Settings/Venue/VenueDetails';
import ServiceStatus from '../pages/Settings/Venue/ServiceStatus';
import VoucherSettings from '../pages/Settings/Venue/VoucherSettings';
import UpsellsSettings from '../pages/Settings/Venue/UpsellsSettings';
import ServiceChargeSettings from '../pages/Settings/Venue/ServiceCharge';
import AdditionalChargeSettings from '../pages/Settings/Venue/AdditionalCharge';
import Courses from '../pages/Settings/Venue/Courses';
import BusinessDetails from '../pages/Settings/Business/BusinessDetails';
import PaymentDetails from '../pages/Settings/Business/PaymentDetails';
import ReportEmails from '../pages/Settings/Business/ReportEmails';
import UpsellsPage from '../pages/Upsells';
import ItemUpsells from '../pages/Upsells/ItemUpsells';
// import Shop from '../pages/Shop';
import AddCustomUpsellGroupForm from '../pages/Upsells/AddCustomUpsellGroupForm';
import EditCustomUpsellGroup from '../pages/Upsells/EditCustomUpsellGroup';
import CheckoutUpsellGroup from '../pages/Upsells/CheckoutUpsellGroup';
import Invoices from '../pages/Invoices';
import Accounts from '../pages/Accounts';
import AccountOrders from '../pages/Accounts/AccountOrders';
import CashUpDetails from '../pages/Tills/CashUpDetails';
import CustomerSettings from '../pages/CustomerSettings';
import PlatformInvoices from '../pages/PlatformInvoices';
import DeliveryStatements from '../pages/DeliveryStatements';
import ActiveVenueWatcher from '../components/ActiveVenueWatcher';
import BrandsMenuManagement from '../pages/Brands/BrandsMenuManagement';
import BrandsItems from '../pages/Brands/BrandItems';
import AddBrandsMenuItem from '../pages/Brands/BrandsMenuItems/AddMenuItem';
import BrandsMenuItem from '../pages/Brands/BrandsMenuItems/BrandsMenuItem';
import BrandsIngredientsList from '../pages/Brands/BrandsIngredientsList';
import BrandsModifierAttributes from '../pages/Brands/BrandsAttributes/BrandsModifierAttributes';
import BrandsMenu from '../pages/Brands/BrandsMenu';
import DeliveryOpeningHours from '../pages/Settings/Venue/DeliveryOpeningHours';
import updateGtmDataLayer from '../shared/utils/updateGtmDataLayer';
import ReportDelivery from '../pages/ReportDelivery';
import SessionsScore from '../pages/SessionsScore';
import Kiosks from '../pages/Kiosks';
import BrandSettings from '../pages/BrandSettings';
import useSwitchVenue from '../hooks/useSwitchVenue';
import StripeAccountContainer from '../pages/Settings/Business/StripeAccountContainer';
import Menus from '../pages/Menus';

const ConditionalRoute = ({
  path,
  children,
  title,
  blockStandard,
  blockDeliveryOnly,
  isOnlyDelivery,
  accessrole,
}) => {
  const typeStandard = useSelector(isStandard);
  const typeDeliveryOnly = useSelector(isDeliveryOnly);
  const typePro = useSelector(isPro);
  const { isAdmin, isRoleAtLeast } = useRoles();

  const showStandardLockedPage = !isAdmin() && typeStandard && blockStandard;
  const showDeliveryOnlyLockedPage = !isAdmin() && typeDeliveryOnly && blockDeliveryOnly;
  const showNotDeliveryOnlyLockedPage = isOnlyDelivery ? !typeDeliveryOnly : false;
  const blockUnknwonVenueType =
    !isAdmin() && !typeStandard && !typePro && !typeDeliveryOnly && path !== '/home';
  const showRoleLockedPage = accessrole && !isRoleAtLeast(accessrole);
  const type = (blockStandard && LOCKTYPES.STANDARD) || LOCKTYPES.ROLE;

  if (
    !blockUnknwonVenueType &&
    !showRoleLockedPage &&
    !showStandardLockedPage &&
    !showDeliveryOnlyLockedPage &&
    !showNotDeliveryOnlyLockedPage
  ) {
    return <Route path={path}>{children}</Route>;
  }
  return (
    <Route path={path}>
      <Locked title={title} type={type} />
    </Route>
  );
};

const AuthenticatedApp = () => {
  const dispatch = useDispatch();
  const { loading: loadingVenues, data: venues } = useSelector(getVenuesState);
  const { data: venue } = useSelector(getVenueState);
  const { data: appState } = useSelector((state) => state.app.session);
  const [defaultPath, setDefaultPath] = useState('/home');
  const { isAdmin } = useRoles();
  const location = useLocation();
  const switchVenue = useSwitchVenue();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const venueId = queryParams.get('venueId');

    if (venueId) {
      switchVenue(venueId);
    }
  }, [location.search, switchVenue, venues]);

  useEffect(() => {
    const firstFetch = async () => {
      await dispatch(fetchVenues());
      dispatch(fetchVenue());
    };
    if (!venues && !loadingVenues) firstFetch();
  }, [dispatch, loadingVenues, venues]);

  useEffect(() => {
    if (!getFirstLogin()) setDefaultPath('/home');
    if (venue && appState && appState.username) {
      updateGtmDataLayer({ userId: appState.username, venueId: venue.venueId });
    }
  }, [appState, venue, venues]);

  if (loadingVenues) {
    return <LogoUpCo />;
  }

  return (
    <Layout>
      <>
        <VenueWatcher />
        {!isAdmin() && <ActiveVenueWatcher />}
        {venues && venue && (
          <Switch>
            <ConditionalRoute path="/home" title="Home">
              <Home />
            </ConditionalRoute>
            <ConditionalRoute
              path="/admin/plan-settings/customer"
              title="Customer Stripe details"
              accessrole={ROLES.ADMIN}
            >
              <CustomerSettings />
            </ConditionalRoute>
            <ConditionalRoute path="/admin/venue-fees" title="Venue fees" accessrole={ROLES.ADMIN}>
              <VenueFees />
            </ConditionalRoute>
            <ConditionalRoute path="/admin" title="Admin" accessrole={ROLES.ADMIN}>
              <Admin />
            </ConditionalRoute>
            <ConditionalRoute
              path="/accounts/:accountId/orders"
              title="Orders"
              accessrole={ROLES.MANAGER}
            >
              <AccountOrders />
            </ConditionalRoute>
            <ConditionalRoute
              path="/accounts"
              title="Accounts"
              accessrole={ROLES.MANAGER}
              blockStandard
            >
              <Accounts />
            </ConditionalRoute>
            <ConditionalRoute
              path="/images"
              title="Images"
              accessrole={ROLES.ADMIN}
              blockDeliveryOnly
            >
              <Images />
            </ConditionalRoute>
            <ConditionalRoute
              path="/create-campaign"
              title="Create campaign"
              accessrole={ROLES.ADMIN}
              blockDeliveryOnly
            >
              <CreateCampaign />
            </ConditionalRoute>
            <ConditionalRoute path="/menus/:menuName" title="Menu" accessrole={ROLES.MANAGER}>
              <Menu />
            </ConditionalRoute>
            <ConditionalRoute path="/menus" title="Menu" accessrole={ROLES.MANAGER}>
              <Menus />
            </ConditionalRoute>
            <ConditionalRoute path="/categories" title="Categories" accessrole={ROLES.MANAGER}>
              <Categories />
            </ConditionalRoute>
            <ConditionalRoute
              exact
              blockStandard
              path="/offers/add"
              title="Campaign"
              accessrole={ROLES.MANAGER}
              blockDeliveryOnly
            >
              <Promotion />
            </ConditionalRoute>
            <ConditionalRoute
              exact
              path="/offers/:campaignId"
              title="Campaign"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliveryOnly
            >
              <Promotion />
            </ConditionalRoute>
            <ConditionalRoute
              path="/offers"
              title="Promotions"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliveryOnly
            >
              <Promotions />
            </ConditionalRoute>
            <ConditionalRoute
              path="/discounts/promo-codes"
              title="Promo codes"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliveryOnly
            >
              <PromoCodes />
            </ConditionalRoute>
            <ConditionalRoute
              path="/item-discounts"
              title="Item discounts"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliveryOnly
            >
              <Discounts />
            </ConditionalRoute>
            <ConditionalRoute
              path="/item-discount/:discountId/addItems"
              title="Add items to item discount"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliveryOnly
            >
              <DiscountAddItem />
            </ConditionalRoute>
            <ConditionalRoute
              path="/item-discount/:discountId"
              title="Discount"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliveryOnly
            >
              <Discount />
            </ConditionalRoute>
            <ConditionalRoute
              path="/upsells/checkout-upsells"
              title="Checkout upsell groups"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliveryOnly
            >
              <CheckoutUpsellGroup />
            </ConditionalRoute>
            <ConditionalRoute
              path="/upsells/item-upsells/upsellgroup/:upsellGroupId"
              title="Update custom upsell"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliveryOnly
            >
              <EditCustomUpsellGroup />
            </ConditionalRoute>
            <ConditionalRoute
              path="/upsells/item-upsells/create-upsell"
              title="Create custom upsell"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliveryOnly
            >
              <AddCustomUpsellGroupForm />
            </ConditionalRoute>
            <ConditionalRoute
              path="/upsells/item-upsells"
              title="Item upsells"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliverOnly
            >
              <ItemUpsells />
            </ConditionalRoute>
            <ConditionalRoute
              path="/upsells"
              title="Upsells"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliveryOnly
            >
              <UpsellsPage />
            </ConditionalRoute>
            <ConditionalRoute
              path="/tables/add"
              title="Add tables"
              accessrole={ROLES.MANAGER}
              blockDeliveryOnly
            >
              <AddTable />
            </ConditionalRoute>
            <ConditionalRoute
              path="/tables/addMultiple"
              title="Add multiple tables"
              accessrole={ROLES.MANAGER}
              blockDeliveryOnly
            >
              <AddMultipleTables />
            </ConditionalRoute>
            <ConditionalRoute
              path="/tables/:tableName"
              title="Table"
              accessrole={ROLES.MANAGER}
              blockDeliveryOnly
            >
              <Table />
            </ConditionalRoute>
            <ConditionalRoute
              path="/tables"
              title="Tables"
              accessrole={ROLES.MANAGER}
              blockDeliveryOnly
            >
              <Tables />
            </ConditionalRoute>
            <ConditionalRoute
              path="/reports/staff/:tabname?"
              title="Staff Reports"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliveryOnly
            >
              <ReportStaff />
            </ConditionalRoute>
            <ConditionalRoute
              path="/reports/sales/:tabname?"
              title="Sales Reports"
              accessrole={ROLES.MANAGER}
              blockStandard
            >
              <ReportSales />
            </ConditionalRoute>
            <ConditionalRoute
              path="/reports/customers/:tabname?"
              title="Customers Reports"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliveryOnly
            >
              <ReportCustomers />
            </ConditionalRoute>
            <ConditionalRoute path="/reports/overview" title="Reports" accessrole={ROLES.MANAGER}>
              <ReportOverview />
            </ConditionalRoute>
            <ConditionalRoute path="/reports/delivery-metrics" title="Delivery Metrics">
              <ReportDelivery />
            </ConditionalRoute>
            <ConditionalRoute path="/reports/sessions-score" title="Sessions Score">
              <SessionsScore />
            </ConditionalRoute>
            <ConditionalRoute
              blockStandard
              blockDeliverOnly
              path="/menu-items/add"
              title="Add menu item"
              accessrole={ROLES.MANAGER}
            >
              <AddMenuItem />
            </ConditionalRoute>
            <ConditionalRoute
              path="/items/:tabname?/:itemId?"
              title="Items"
              accessrole={ROLES.MANAGER}
            >
              <ItemTabs />
            </ConditionalRoute>
            <ConditionalRoute
              path="/items/modifiers/:modifierId?"
              title="Items"
              accessrole={ROLES.MANAGER}
            >
              <ItemTabs />
            </ConditionalRoute>
            <ConditionalRoute
              path="/menu-items/:menuItemId/attributes"
              title="Attributes"
              accessrole={ROLES.MANAGER}
            >
              <Attributes />
            </ConditionalRoute>
            <ConditionalRoute
              path="/menu-items/:menuItemId/modifierAttributes"
              title="Modifier Attributes"
              accessrole={ROLES.MANAGER}
            >
              <ModifierAttributes />
            </ConditionalRoute>
            <ConditionalRoute
              path="/menu-items/:menuItemId"
              title="Menu item"
              accessrole={ROLES.MANAGER}
            >
              <MenuItem />
            </ConditionalRoute>
            <ConditionalRoute
              path="/settings/venue/venue-details"
              title="Venue details"
              accessrole={ROLES.OWNER}
              blockDeliveryOnly
            >
              <VenueDetails />
            </ConditionalRoute>
            <ConditionalRoute
              path="/settings/venue/opening-hours"
              title="Opening Hours"
              accessrole={ROLES.MANAGER}
              blockStandard
            >
              <OpeningHours />
            </ConditionalRoute>
            <ConditionalRoute
              path="/settings/venue/delivery-opening-hours"
              title="Delivery Opening Hours"
              accessrole={ROLES.MANAGER}
            >
              <DeliveryOpeningHours />
            </ConditionalRoute>
            <ConditionalRoute
              path="/settings/venue/service-status"
              title="Venue details"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliveryOnly
            >
              <ServiceStatus />
            </ConditionalRoute>
            <ConditionalRoute
              path="/settings/venue/voucher-settings"
              title="Vouchers"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliveryOnly
            >
              <VoucherSettings />
            </ConditionalRoute>
            <ConditionalRoute
              path="/settings/venue/upsells"
              title="Upsells"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliveryOnly
            >
              <UpsellsSettings />
            </ConditionalRoute>
            <ConditionalRoute
              path="/settings/venue/service-charge"
              title="Service Charge"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliveryOnly
            >
              <ServiceChargeSettings />
            </ConditionalRoute>
            <ConditionalRoute
              path="/settings/venue/additional-charge"
              title="Additional Charge"
              accessrole={ROLES.MANAGER}
            >
              <AdditionalChargeSettings />
            </ConditionalRoute>
            <ConditionalRoute
              path="/settings/venue/courses"
              title="Courses"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliveryOnly
            >
              <Courses />
            </ConditionalRoute>
            <ConditionalRoute
              path="/settings/business/business-details"
              title="Business Details"
              accessrole={ROLES.MANAGER}
              blockDeliveryOnly
              blockStandard
            >
              <BusinessDetails />
            </ConditionalRoute>
            <ConditionalRoute
              path="/settings/business/payment-details-old"
              title="Payment Details"
              accessrole={ROLES.ADMIN}
              blockStandard
              blockDeliveryOnly
            >
              <PaymentDetails />
            </ConditionalRoute>
            <ConditionalRoute
              path="/settings/business/payment-details"
              title="Payment Details"
              accessrole={ROLES.OWNER}
              blockStandard
              blockDeliveryOnly
            >
              <StripeAccountContainer />
            </ConditionalRoute>
            <ConditionalRoute
              path="/settings/business/report-emails"
              title="Report Emails"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliveryOnly
            >
              <ReportEmails />
            </ConditionalRoute>
            <ConditionalRoute
              path="/settings/business/platform-invoices"
              title="Platform Invoices"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliveryOnly
            >
              <PlatformInvoices />
            </ConditionalRoute>
            <ConditionalRoute
              path="/settings/business/delivery-statements"
              title="Delivery Statements"
              accessrole={ROLES.MANAGER}
              blockStandard
            >
              <DeliveryStatements />
            </ConditionalRoute>
            <ConditionalRoute
              path="/settings/:tabname?"
              title="Settings"
              accessrole={ROLES.MANAGER}
            >
              <Settings />
            </ConditionalRoute>
            <ConditionalRoute
              path="/invoices/:tabname?"
              title="Invoices"
              accessrole={ROLES.MANAGER}
            >
              <Invoices />
            </ConditionalRoute>

            <ConditionalRoute
              path="/venues/personalisation"
              title="Personalisation"
              accessrole={ROLES.MANAGER}
              blockDeliveryOnly
            >
              <Personalisation />
            </ConditionalRoute>
            <ConditionalRoute
              path="/venues/team"
              title="Team"
              accessrole={ROLES.MANAGER}
              blockDeliveryOnly
            >
              <Team />
            </ConditionalRoute>
            <ConditionalRoute path="/venues" title="Venues">
              <Venues />
            </ConditionalRoute>
            <ConditionalRoute
              path="/devices/edit/:deviceId"
              title="Edit device"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliveryOnly
            >
              <EditDevice />
            </ConditionalRoute>
            <ConditionalRoute
              path="/devices/add"
              title="Add device"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliveryOnly
            >
              <AddDevice />
            </ConditionalRoute>
            <ConditionalRoute path="/devices" title="Devices" accessrole={ROLES.MANAGER}>
              <Devices />
            </ConditionalRoute>
            <ConditionalRoute
              path="/printers/add"
              title="Add printer"
              accessrole={ROLES.ADMIN}
              blockStandard
              blockDeliveryOnly
            >
              <AddPrinter />
            </ConditionalRoute>
            <ConditionalRoute
              path="/printers/edit/:printerId"
              title="Edit printer"
              accessrole={ROLES.ADMIN}
              blockStandard
              blockDeliveryOnly
            >
              <EditPrinter />
            </ConditionalRoute>
            <ConditionalRoute
              path="/mobiles/edit/:mobileDeviceId"
              title="Edit mobile"
              accessrole={ROLES.MANAGER}
              blockDeliveryOnly
              blockStandard
            >
              <EditMobile />
            </ConditionalRoute>
            <ConditionalRoute
              path="/mobiles"
              title="Mobiles"
              accessrole={ROLES.MANAGER}
              blockDeliveryOnly
            >
              <Mobiles />
            </ConditionalRoute>
            <ConditionalRoute
              path="/kiosks"
              accessrole={ROLES.MANAGER}
              title="Kiosks"
              blockDeliveryOnly
            >
              <Kiosks />
            </ConditionalRoute>
            <ConditionalRoute
              path="/staff/edit/:staffId"
              accessrole={ROLES.MANAGER}
              title="Edit staff"
              blockDeliveryOnly
            >
              <EditStaff />
            </ConditionalRoute>
            <ConditionalRoute
              path="/staff/add"
              title="Add staff"
              accessrole={ROLES.MANAGER}
              blockDeliveryOnly
            >
              <AddStaff />
            </ConditionalRoute>
            <ConditionalRoute
              path="/staff"
              title="Staff"
              accessrole={ROLES.MANAGER}
              blockDeliveryOnly
            >
              <Staff />
            </ConditionalRoute>
            <ConditionalRoute path="/tabs" title="Tabs" blockDeliveryOnly>
              <Tabs />
            </ConditionalRoute>
            <ConditionalRoute path="/tab/:tabId" title="Tab" blockDeliverOnly>
              <Tab />
            </ConditionalRoute>
            <ConditionalRoute
              path="/vouchers"
              title="Vouchers"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliveryOnly
            >
              <VoucherTabs />
            </ConditionalRoute>
            <ConditionalRoute
              path="/vouchers/:tabname?"
              title="Vouchers"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliverOnly
            >
              <VoucherTabs />
            </ConditionalRoute>
            <ConditionalRoute
              path="/voucher/:voucherCode"
              title="Voucher"
              accessrole={ROLES.MANAGER}
              blockDeliveryOnly
            >
              <Voucher />
            </ConditionalRoute>
            <ConditionalRoute
              path="/qrcodes/add"
              title="Add QR code"
              accessrole={ROLES.MANAGER}
              blockDeliveryOnly
            >
              <AddQrcode />
            </ConditionalRoute>
            <ConditionalRoute
              path="/qrcodes"
              title="QR codes"
              accessrole={ROLES.MANAGER}
              blockDeliveryOnly
            >
              <Qrcodes />
            </ConditionalRoute>
            <ConditionalRoute
              path="/payouts"
              title="Payouts"
              accessrole={ROLES.MANAGER}
              blockDeliveryOnly
            >
              <Payouts />
            </ConditionalRoute>
            <ConditionalRoute path="/orders-live" title="Live orders">
              <OrdersLive />
            </ConditionalRoute>
            <ConditionalRoute path="/orders-takeaway" title="Takeaway orders" blockDeliveryOnly>
              <OrdersTakeaway />
            </ConditionalRoute>
            <ConditionalRoute path="/orders/:orderId" title="Order">
              <Order />
            </ConditionalRoute>
            <ConditionalRoute path="/orders-history/:backLink?" title="Orders">
              <Orders />
            </ConditionalRoute>
            <ConditionalRoute
              path="/customer-reviews"
              title="Customer reviews"
              accessrole={ROLES.MANAGER}
            >
              <CustomerReviews />
            </ConditionalRoute>
            <ConditionalRoute
              path="/sales-areas"
              title="Sales areas"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliveryOnly
            >
              <SalesAreas />
            </ConditionalRoute>
            <ConditionalRoute
              path="/sales-area/:salesAreaId"
              title="Sales area"
              accessrole={ROLES.MANAGER}
              blockStandard
              blockDeliveryOnly
            >
              <SalesArea />{' '}
            </ConditionalRoute>

            {/* <ConditionalRoute path="/store" title="Store" accessrole={ROLES.MANAGER} > */}
            {/*   <Shop /> */}
            {/* </ConditionalRoute> */}
            <ConditionalRoute
              path="/tills/cashup/:cashupId"
              title="Cashup"
              accessrole={ROLES.MANAGER}
              blockDeliveryOnly
            >
              <CashUpDetails />
            </ConditionalRoute>
            <ConditionalRoute
              path="/tills"
              title="Tills"
              accessrole={ROLES.MANAGER}
              blockDeliveryOnly
            >
              <Tills />
            </ConditionalRoute>
            <ConditionalRoute
              blockStandard
              blockDeliverOnly
              path="/brand-menu-management/items/add"
              title="Add brand item"
              accessrole={ROLES.ADMIN}
            >
              <AddBrandsMenuItem />
            </ConditionalRoute>
            <ConditionalRoute
              path="/brand-menu-management/items/:tabname?/:itemId?"
              title="Brand Menu Items"
              accessrole={ROLES.ADMIN}
            >
              <BrandsItems />
            </ConditionalRoute>
            <ConditionalRoute
              blockStandard
              blockDeliverOnly
              path="/brand-menu-management/item/:menuItemId/ingredients"
              title="Brands Ingredients"
              accessrole={ROLES.ADMIN}
            >
              <BrandsIngredientsList />
            </ConditionalRoute>
            <ConditionalRoute
              blockStandard
              blockDeliverOnly
              path="/brand-menu-management/item/:menuItemId/modifier-attributes"
              title="Brands Modifiers"
              accessrole={ROLES.ADMIN}
            >
              <BrandsModifierAttributes />
            </ConditionalRoute>
            <ConditionalRoute
              blockStandard
              blockDeliverOnly
              path="/brand-menu-management/item/:menuItemId"
              title="Brands Item"
              accessrole={ROLES.ADMIN}
            >
              <BrandsMenuItem />
            </ConditionalRoute>
            <ConditionalRoute
              path="/brand-menu-management/menus/:menuId"
              title="Brands Menu"
              accessrole={ROLES.ADMIN}
            >
              <BrandsMenu />
            </ConditionalRoute>
            <ConditionalRoute
              path="/brand-menu-management/:brandId/settings"
              title="Brand Settings"
              accessrole={ROLES.ADMIN}
            >
              <BrandSettings />
            </ConditionalRoute>
            <ConditionalRoute
              path="/brand-menu-management"
              title="Brand Menu Management"
              accessrole={ROLES.ADMIN}
            >
              <BrandsMenuManagement />
            </ConditionalRoute>

            <Route render={() => <Redirect to={defaultPath} />} />
          </Switch>
        )}
      </>
    </Layout>
  );
};

export default AuthenticatedApp;
